@media (min-width: 476px) {
  .searchbar {
    margin-bottom: auto;
    margin-top: auto;
    height: 40px;
    background-color: #353b48;
    border-radius: 30px;
    padding: 10px;
  }
}
.searchbar {
  margin-bottom: auto;
  margin-top: auto;
  height: 60px;
  background-color: #353b48;
  border-radius: 30px;
  padding: 10px;
}

.search_input {
  color: white;
  border: 0;
  outline: 0;
  background: none;
  width: 0;
  caret-color: transparent;
  line-height: 40px;
  /* transition: width 0.4s linear; */
}

.searchbar:hover > .search_input {
  padding: 0 10px;
  width: 450px;
  caret-color: red;
  /* transition: width 0.4s linear; */
}

.searchbar:hover > .search_icon {
  background: white;
  color: #e74c3c;
}

.search_icon {
  height: 40px;
  width: 40px;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  text-decoration: none;
}

.mb-60 {
  margin-bottom: 60px;
}

body {
  /* background-image: url('../assets/images/12.jpg'); */
  background-color: #ffffff;
  background-position: center;
  background-size: cover;
  -webkit-font-smoothing: antialiased;
  /* font-family: 'Hanalei Fill', cursive !important; */
  /* font: normal 14px Roboto, arial, sans-serif; */
  /* font-family: "Dancing Script", cursive !important; */
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #353b48;
  background-image: none;
  flex: 1;
  padding: 0 0.5em;
  color: #fff;
  cursor: pointer;
}

/* Remove IE arrow */

select::-ms-expand {
  display: none;
}

/* Custom Select */

.select {
  position: relative;
  display: flex;
  width: 20em;
  height: 2.5em;
  line-height: 2;
  background: #353b48;
  overflow: hidden;
  border-radius: 0.25em;
}

/* Arrow */

.select::after {
  content: "\25BC";
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 1em;
  background: #34495e;
  cursor: pointer;
  pointer-events: none;
  /* -webkit-transition: .25s all ease; */
  /* -o-transition: .25s all ease; */
  /* transition: .25s all ease; */
}

/* Transition */

.select:hover::after {
  color: #2196f3;
}

/* checkbox*/

.content {
  background-color: #e74c3c;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-around;
}

.content div {
  flex-basis: 250px;
  border: 1px solid #ccc;
  padding: 1rem;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.2);
}

input[type="checkbox"].demo3 {
  display: none;
}

input[type="checkbox"].demo3 + label::before {
  font-family: "Font Awesome 5 Free", sans-serif;
  content: "\f0c8";
  font-size: 1.2em;
  color: #00b7e8;
  margin-right: 0.3rem;
}

input[type="checkbox"].demo3:checked + label::before {
  content: "\f14a";
  color: #00b7e8;
}

.disabledCursor {
  cursor: default;
}
