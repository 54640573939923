

.card {
  position:relative;
}
  
.card .card-badge {
  position:absolute;
  top:-10px;
  left:-30px;
  padding:5px;
  background:blue;
  color:white;
  transform:rotate(-20deg);
}